<template>
  <div>
    <div class="my-2">
      <!-- Table Top -->

      <div class="tw-flex tw-items-center tw-gap-[12px] tw-justify-end">
        <!-- Search -->
        <search-input
          :placeholder="'Cari Tryout berdasrkan Nama...'"
          :onInput="onSearch"
        />
        <b-button variant="primary" @click="fetchData(search)">
          <feather-icon class="mr-1" icon="RefreshCcwIcon" />
          <span class="text-nowrap">Refresh</span>
        </b-button>

        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.tambah-kategori-Tryout
          variant="primary"
        >
          <feather-icon class="mr-1" icon="PlusCircleIcon" />
          <span class="text-nowrap">Tambah</span>
        </b-button>
      </div>
    </div>

    <!-- Filter By -->
    <!-- <div class="tw-inline-flex tw-mb-6">
      <custom-filter
        :data="filterList"
        :handleChange="handleChange"
        :filterValue="filterValue"
        :initialValue="initialValue"
      />
    </div> -->

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <!-- modal tambah kategori Tryout-->
      <b-modal
        id="tambah-kategori-Tryout"
        cancel-variant="outline-secondary"
        :ok-title="targetedId ? 'Edit' : 'Tambah'"
        cancel-title="Cancel"
        centered
        no-close-on-backdrop
        size="lg"
        :title="targetedId ? 'Edit Paket Tryout' : 'Tambah Paket Tryout'"
        @show="(_) => showModal(_, targetedId ? 'edit' : 'store')"
        @hidden="resetModal"
        @ok="(e) => handleOk(e, targetedId ? 'edit' : 'store')"
      >
        <b-form>
          <b-overlay :show="isSubmitting" rounded="sm">
            <b-card-actions title="Informasi Paket" action-collapse>
              <b-form-group>
                <label for="nama">Nama</label>
                <b-form-input
                  for="nama"
                  v-model.trim="form.nama"
                  type="text"
                  placeholder="Nama paket tryout"
                />
              </b-form-group>

              <b-form-group>
                <label for="nama">Label</label>
                <b-form-input
                  for="label"
                  v-model.trim="form.label"
                  type="text"
                  placeholder="Label paket tryout"
                />
              </b-form-group>

              <b-row>
                <b-col sm="12" md="6">
                  <b-form-group label="Jenis" label-for="jenis">
                    <v-select
                      id="jenis"
                      v-model="form.jenis"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="nama"
                      :options="listJenis"
                      :reduce="option => option.nama"
                      @option:selected="onChangeJenis"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="3" md="3">
                  <b-form-group>
                    <label for="show">Tampilkan</label>
                    <b-form-checkbox
                      id="show"
                      :checked="!!form.show"
                      v-model="form.show"
                      class="custom-control-primary"
                      name="check-button"
                      switch
                    />
                  </b-form-group>
                </b-col>

                <b-col sm="3" md="3">
                  <b-form-group>
                    <label for="show">Status</label>
                    <b-form-checkbox
                      id="is_active"
                      :checked="!!form.is_active"
                      v-model="form.is_active"
                      class="custom-control-primary"
                      name="check-button"
                      switch
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="12" md="6">
                  <b-form-group label="Kategori" label-for="kategori_tryout_id">
                    <v-select
                      id="kategori_tryout_id"
                      v-model="form.kategori_tryout_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="listKategoriTryout"
                      :reduce="option => option.id"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row v-if="form.jenis === 'PREMIUM'">
                <b-col sm="12" md="6">
                  <b-form-group>
                    <label for="harga">Harga</label>
                    <b-form-input
                      id="harga"
                      v-model.number="form.harga"
                      type="number"
                      placeholder="Harga"
                      min="0"
                      step="1000"
                    />
                  </b-form-group>
                </b-col>

                <b-col sm="12" md="6">
                  <b-form-group>
                    <label for="harga">Harga Diskon</label>
                    <b-form-input
                      id="harga_diskon"
                      v-model.number="form.harga_diskon"
                      type="number"
                      placeholder="Harga"
                      min="0"
                      step="1000"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col sm="12" md="6">
                  <b-form-group>
                    <label for="tanggal-ujian">Tanggal Ujian</label>
                    <date-picker
                      class="w-100"
                      type="datetime"
                      v-model="form.tanggal_ujian"
                      value-type="YYYY-MM-DD HH:mm"
                    ></date-picker>
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="6">
                  <b-form-group>
                    <label for="tanggal-penilaian">Tanggal Penilaian</label>
                    <date-picker
                      class="w-100"
                      type="datetime"
                      v-model="form.tanggal_penilaian"
                      value-type="YYYY-MM-DD HH:mm"
                    ></date-picker>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-actions>

            <b-card-actions title="List Paket" action-collapse>
              <div class="mb-1 d-flex align-items-center justify-content-end">
                <b-button variant="outline-primary" @click="tambahPaketMapel">
                  <feather-icon icon="PlusCircleIcon" class="mr-50" />
                  <span class="text-nowrap">Tambah Paket</span>
                </b-button>
              </div>

              <draggable
                tag="ul"
                :list="form.paket_kategoris"
                class="list-group"
                handle=".handle"
              >
                <li
                  class="list-group-item d-flex align-items-center"
                  v-for="(element, idx) in form.paket_kategoris"
                  :key="idx"
                >
                  <feather-icon icon="MenuIcon" class="mr-1 handle button" />

                  <div class="w-100">
                    <b-row class="w-100">
                      <b-col>
                        <b-form-group
                          label="Paket Kategori"
                          label-for="paket-mapel"
                        >
                          <v-select
                            id="paket-mapel"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="title"
                            :options="listPaketKategori"
                            v-model="element.id"
                            :reduce="item => item.id"
                            @option:selected="(e) => setSelectedPaketMapel(e, idx)"
                          >
                            <template #option="{ title, jumlah }">
                              <div class="pr-2 d-flex justify-content-between">
                                <span>{{ title }}</span>
                                <span>{{ jumlah }}</span>
                              </div>
                            </template>
                          </v-select>
                        </b-form-group>
                      </b-col>
                      <b-col cols="3">
                        <b-form-group label="Durasi" label-for="durasi">
                          <b-form-input
                            id="durasi"
                            type="number"
                            disabled
                            v-model.number="element.durasi"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row class="w-100">
                      <b-col>
                        <b-form-group
                          label="Kategori/ Mapel"
                          label-for="kategori-mapel"
                        >
                          <b-form-input
                            id="kategori-mapel"
                            disabled
                            v-model="element.kategori"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="3">
                        <b-form-group
                          label="Jumlah Soal"
                          label-for="jumlah-soal"
                        >
                          <b-form-input
                            id="jumlah-soal"
                            disabled
                            v-model="element.jumlah"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>

                  <!-- <input type="text" class="form-control" v-model="element.text" /> -->
                  <feather-icon
                    icon="XIcon"
                    class="ml-1"
                    @click="removePaketMapel(idx)"
                  />
                </li>
              </draggable>
            </b-card-actions>
          </b-overlay>
        </b-form>
      </b-modal>

      <!-- modal hapus kategori Tryout-->
      <b-modal
        id="hapus-paket-tryout"
        cancel-variant="outline-secondary"
        ok-title="Hapus"
        cancel-title="Close"
        centered
        title="Hapus"
        @show="resetModal"
        @hidden="resetModal"
        @ok="deletePaketTryout(listPaketTryout[currentPaketIndex].id)"
      >
        <b-form>
          <b-overlay :show="isSubmitting" rounded="sm">
            <b-form-group>
              <p>Apakah anda yakin ingin menghapus?</p>
            </b-form-group>
          </b-overlay>
        </b-form>
      </b-modal>

      <!-- modal lihat paket Tryout-->

      <b-modal
        id="lihat-kategori-Tryout"
        cancel-variant="outline-secondary"
        cancel-title="Cancel"
        centered
        size="lg"
        v-model="showLihat"
        title="Lihat Kategori Tryout"
      >
        <b-form v-if="showLihat">
          <ul class="list-group">
            <li
              class="list-group-item d-flex align-items-center"
              v-for="(element, idx) in form.paket_kategoris"
              :key="idx"
            >
              <div class="w-100">
                <b-row class="w-100">
                  <b-col cols="9">
                    <b-form-group label="Paket Mapel" label-for="paket-mapel">
                      <b-form-input
                        id="paket-mapel"
                        type="text"
                        v-model.number="element.nama"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="3">
                    <b-form-group label="Durasi" label-for="durasi">
                      <b-form-input
                        id="durasi"
                        type="number"
                        v-model.number="element.total_durasi"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="1" class="col align-self-center">
                    <!-- <b-button
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      :to="{ name: 'manajemen-paket-detail', params: { id: element.id } }"
                    >
                      <feather-icon icon="EyeIcon" />
                    </b-button>-->
                  </b-col>
                </b-row>
                <b-row class="w-100">
                  <b-col>
                    <b-form-group label="Kategori" label-for="kategori-mapel">
                      <b-form-input
                        id="kategori-mapel"
                        disabled
                        v-model="element.kategori_soal.nama"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="3">
                    <b-form-group label="Jumlah Soal" label-for="jumlah-soal">
                      <b-form-input
                        id="jumlah-soal"
                        disabled
                        v-model="element.total_soal"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </li>
          </ul>
        </b-form>
      </b-modal>

      <b-table
        :busy="isLoading"
        class="position-relative"
        :items="listPaketTryout"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        striped
        hover
        empty-text="Tidak ada data ditemukan"
      >
        <template #table-busy>
          <div class="my-2 text-center text-danger">
            <b-spinner class="mr-1 align-middle"></b-spinner>
            <strong>Sedang memuat data...</strong>
          </div>
        </template>

        <template #cell(is_active)="data">
          {{!!data.value ? "Aktif" : "Non-aktif"}}
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="show(data.item.id)">
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">Lihat Paket</span>
            </b-dropdown-item>

            <b-dropdown-item @click="download(data.item.id, data.item.nama)">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Download Pengerjaan</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="downloadReport(data.item.id, data.item.nama)"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Download Hasil</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="setTargetedId(data.item.id)"
              v-b-modal.tambah-kategori-Tryout
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="setRowIndex(data.item)"
              v-b-modal.hapus-paket-tryout
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <!-- Footer -->
      <div class="mx-2 mb-2">
        <pagination
          :from="from"
          :to="to"
          :pageTotal="pageTotal"
          :total="total"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BModal,
  VBModal,
  BForm,
  BFormGroup,
  BCardText,
  BButton,
  BTable,
  BListGroupItem,
  BDropdown,
  BDropdownItem,
  BSpinner,
  BFormCheckbox,
  BOverlay,
} from "bootstrap-vue";
// import store from "@/store";
import { ref, onMounted, watch } from "@vue/composition-api";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";

import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import draggable from "vuedraggable";
import vSelect from "vue-select";
import { downloadFile, formatDatePayload, useRouter } from "@core/utils/utils";

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

// Fetch
import repository from "@repofactory";
const repoKategoriTryout = repository.get("kategoriTryoutRepository");
const repoPaketKategori = repository.get("paketKategoriRepository");
const repoPaketTryout = repository.get("paketTryoutRepository");
const repoHasilTryout = repository.get("HasilTryoutRepositories");
const repoJenis = repository.get("JenisRepository");

import CustomFilter from '@/@core/components/base/CustomFilter.vue';
import SearchInput from '@/@core/components/base/SearchInput.vue';
import debounce from 'lodash.debounce';
import Pagination from '@/@core/components/base/Pagination.vue';

export default {
  components: {
    // ModalTambah,
    BSpinner,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BModal,
    BFormCheckbox,
    VBModal,
    BForm,
    BFormGroup,
    BCardText,
    BDropdown,
    flatPickr,
    BDropdownItem,
    ToastificationContent,
    BCardActions,
    draggable,
    BListGroupItem,
    vSelect,
    BOverlay, DatePicker,
    CustomFilter,
    SearchInput,
    Pagination
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  setup() {
    const toast = useToast();
    const { route } = useRouter();

    const isLoading = ref(true);
    const modalLihat = ref(null);
    const showLihat = ref(false);
    const isSubmitting = ref(false);
    const isError = ref(false);
    const kategoryTryout = ref([]);
    const listPaketTryout = ref([]);
    const currentPaketIndex = ref(null);
    const form = ref({
      show: false,
      id: null,
      nama: "",
      kategori_tryout_id: null,
      jenis_id: 0,
      jenis: "",
      harga: 0,
      pg: 0,
      show: true,
      tanggal_ujian: null,
      tanggal_penilaian: null,
      is_closed: false,
      paket_kategoris: [],
      label: null,
      harga_diskon: 0,
      is_active: true,
    });
    const listJenis = ref([])
    const targetedId = ref(null)

    const listPaketKategori = ref([]);
    const listKategoriTryout = ref([]);
    const listJenisTryout = ref([
      {
        title: "PREMIUM",
        value: "PREMIUM",
      },
      {
        title: "GRATIS",
        value: "GRATIS",
      },
    ]);

    // Filter and Search
    const filterList = ref([
      {
        name: "Jenis",
        list: []
      },
      // {
      //   name: "Kategori",
      //   list: []
      // }
    ])
    const initialValue = ref({
      "Mata Pelajaran": null,
      // "Kategori": null,
    })
    const filterValue = ref({...initialValue})
    const search = ref("")

        const from = ref(0);
    const to = ref(0);
    const total = ref(0);
    const pageTotal = ref(0);

    const tableColumns = [
      {
        key: "id",
        label: "ID",
        sortable: true,
      },
      // {
      //   key: 'kode',
      //   label: 'Kode Kategori',
      //   formatter: (value, key, item) => {
      //     return value.toUpperCase()
      //   },
      //   sortable: true
      // },
      {
        key: "nama",
        label: "Nama Paket",
        formatter: (value, key, item) => {
          return value.toUpperCase();
        },
        sortable: true,
      },
      {
        key: "jenis",
        label: "Jenis",
        formatter: (value, _, __) => {
          return value.toUpperCase();
        },
        sortable: true,
      },
      {
        key: "kategori_tryout_id",
        label: "Kategori",
        formatter: (value, _, __) => {
          return listKategoriTryout.value.find(item => item.id === value).title || ""
        },
        sortable: true,
      },
      {
        key: "tanggal_ujian",
        label: "Tanggal Ujian",
        sortable: true,
      },
      {
        key: "tanggal_penilaian",
        label: "Tanggal Penilaian",
        sortable: true,
      },
      {
        key: "is_active",
        label: "Status",
      },
      {
        key: "actions",
      },
    ];

    onMounted(async () => {
      fetchData(search.value, route.value.query.page);
      fetchJenis();
    });


    watch([() => route.value.query.page], debounce(() => fetchData(search.value ,route.value.query.page), 500))

    watch([() => search.value], debounce(() => fetchData(search.value, 1), 500))


    const handleOk = (e, method) => {
      e.preventDefault();
      switch (method) {
        case "store":
          submitPaketTryout(e, "store");
          break;

        case "edit":
          submitPaketTryout(e, "edit");
          break;

        case "destroy":
          deletePaketTryout(listPaketTryout.value[currentPaketIndex.value].id);
          break;

        default:
          showToast(
            "Error",
            `Method ${method} tidak tersedia.`,
            "warning",
            "AlertTriangleIcon"
          );
          break;
      }
    };

    const setTargetedId = (value) => {
      targetedId.value = value
    }

    const setRowIndex = (item) => {
      currentPaketIndex.value = listPaketTryout.value.findIndex(
        (paket) => paket.id == item.id
      );
    };

    const showToast = (title, text, variant, icon = "BellIcon") => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    };

    const resetModal = () => {
      form.value = {
        id: null,
        show: false,
        nama: "",
        kategori_tryout_id: null,
        jenis_id: 0,
        jenis: "",
        harga: 0,
        pg: 0,
        show: true,
        tanggal_ujian: null,
        tanggal_penilaian: null,
        is_closed: false,
        paket_kategoris: [],
        label: null,
        is_active: true,
        harga_diskon: 0,
      };
      setTargetedId(null)
    };

    const tambahPaketMapel = () => {
      form.value.paket_kategoris.push({
        durasi: 0,
        kategori: "",
        jumlah: 0,
      });
    };

    const removePaketMapel = (idx) => {
      const current = form.value.paket_kategoris;
      current.splice(idx, 1);
    };

    const setSelectedPaketMapel = (value, idx) => {

      const current = form.value.paket_kategoris[idx];

      current.kategori = value.kategori;
      current.jumlah = value.jumlah;
      current.durasi = value.durasi;
      current.id = value.id;
    };

    const fetchData = async (search, page = 1) => {
      isLoading.value = true;
      fetchPaketKategoris();
      fetchKategoriTryout();

      let kategori = null
      if(route.value.params.jenis === "skd") {
        kategori = 8
      } else if(route.value.params.jenis === "utbk") {
        kategori = 12
      } else if(route.value.params.jenis === "cpns") {
        kategori = 10
      } else if(route.value.params.jenis === "tpa") {
        kategori = 14
      } else if(route.value.params.jenis === "tbi") {
        kategori = 15
      } else if(route.value.params.jenis === "bumn") {
        kategori = 9
      }

      await repoPaketTryout
        .get(search, page, kategori)
        .then(function (response) {
          isLoading.value = false;
          listPaketTryout.value = response.data.data.data
          from.value = response.data.data.from
          to.value = response.data.data.to
          total.value = response.data.data.total
          pageTotal.value = response.data.data.last_page

        })
        .catch(function (error) {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isLoading.value = false;
          isError.value = true;
        });
    };

    const fetchJenis = async () => {
      try {
        const res = await repoJenis.get()
        listJenis.value = res.data.data
        filterList.value[0].list = res.data.data.map(item => ({
          label: item.nama.toUpperCase(),
          value: item.nama.toUpperCase(),
        }))
      } catch (error) {
        if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
      }
    }

    const showModal = (_, action) => {
      if(action === "store") {
        resetModal()
      } else {
        fetchDetail()
      }
    }

    const fetchDetail = async (id = targetedId.value) => {
      return await repoPaketTryout
        .show(id)
        .then(function (response) {
          form.value = {
            ...response.data.data,
            is_active: !!response.data.data.is_active,
            show: !!response.data.data.show
          }
          form.value.paket_kategoris = response.data.data.paket_kategoris.map(item => ({
            ...item,
            jumlah: item.total_soal,
            durasi: item.total_durasi,
            kategori: item.kategori_soal.nama
          }))
          // set peropery paket kategoris on fetch detail
        })
        .catch(function (error) {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isError.value = true;
        });
    }

    const show = async (id) => {
      try {
        await fetchDetail(id)
        showLihat.value = true;

      } catch (error) {
        //
        if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isError.value = true;
      }
    };

    const fetchPaketKategoris = async () => {
      await repoPaketKategori
        .get()
        .then(function (response) {
          listPaketKategori.value = response.data.data.data.map((val) => {
            return {
              id: val.id,
              title: val.nama.toUpperCase(),
              jumlah: val.total_soal,
              durasi: parseFloat(val.total_durasi),
              kategori: val.kategori_soal.nama.toUpperCase(),
            };
          });
        })
        .catch(function (error) {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
        });
    };

    const fetchKategoriTryout = async () => {
      await repoKategoriTryout
        .get()
        .then(function (response) {
          listKategoriTryout.value = response.data.data.map((val) => {
            return {
              id: val.id,
              title: val.nama.toUpperCase(),
              kode: val.kode.toUpperCase(),
            };
          });
        })
        .catch(function (error) {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
        });
    };

    const submitPaketTryout = async (e, action) => {
      isSubmitting.value = true;
      let data = {
        nama: form.value.nama,
        kategori_tryout_id: form.value.kategori_tryout_id,
        jenis: form.value.jenis,
        harga: form.value.harga,
        harga_diskon: form.value.harga_diskon,
        is_active: form.value.is_active,
        jenis_id:form.value.jenis_id,
        label: form.value.label,
        show: form.value.show,
        tanggal_ujian: form.value.tanggal_ujian,
        tanggal_penilaian: form.value.tanggal_penilaian,
        paket_kategoris: form.value.paket_kategoris,
        total_durasi: form.value.paket_kategoris.reduce(
          (n, { durasi }) => n + durasi,
          0
        ),
        total_soal: form.value.paket_kategoris.reduce(
          (n, { jumlah }) => n + jumlah,
          0
        ),
      };

      if(action === "store") {
        await repoPaketTryout
          .create(data)
          .then((response) => {
            listPaketTryout.value.push(response.data.data);

            showToast("Notifikasi", response.data.message, "primary");

            isSubmitting.value = false;
            resetModal();
            e.vueTarget.hide()
          })
          .catch((error) => {
            if (error.response) {
              showToast(
                "Error",
                error.response.data.message,
                "danger",
                "AlertTriangleIcon"
              );
            } else if (error.request) {
              showToast(
                "Error",
                "Tidak bisa request data ke server",
                "danger",
                "AlertTriangleIcon"
              );
            } else {
              showToast("Error", error.message, "danger", "AlertTriangleIcon");
            }
            isSubmitting.value = false;
          });
      } else {
        const newTglpPenilaian = formatDatePayload(data.tanggal_penilaian)
        const newTglUjian = formatDatePayload(data.tanggal_ujian)
        data = {
          ...data,
          paket_kategoris: data.paket_kategoris.map(item => ({
            kategori: item.kategori,
            jumlah: item.jumlah,
            durasi: item.durasi,
            id: item.id
          })),
          tanggal_penilaian: newTglpPenilaian,
          tanggal_ujian: newTglUjian
        }
        await repoPaketTryout
        .update(targetedId.value, data)
        .then((response) => {
          fetchData(search.value)

          showToast("Notifikasi", response.data.message, "primary");

          isSubmitting.value = false;
          resetModal();
          e.vueTarget.hide()
        })
        .catch((error) => {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isSubmitting.value = false;
        });
      }

    };

    const updatekategoryTryout = async () => {
      isSubmitting.value = true;

      await repoKategoriTryout
        .update(
          {
            kode: form.value.kode,
            nama: form.value.nama,
          },
          form.value.id
        )
        .then((response) => {
          const currentKategori = kategoryTryout.value[currentPaketIndex.value];
          currentKategori.kode = response.data.data.kode;
          currentKategori.nama = response.data.data.nama;

          showToast("Notifikasi", response.data.message, "primary");

          isSubmitting.value = false;
          resetModal();
        })
        .catch((error) => {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isSubmitting.value = false;
          resetModal();
        });
    };

    const deletePaketTryout = async (id) => {
      isSubmitting.value = true;

      await repoPaketTryout
        .delete(id)
        .then((response) => {
          listPaketTryout.value.splice(currentPaketIndex.value, 1);
          showToast("Notifikasi", response.data.message, "primary");
          resetModal();

          isSubmitting.value = false;
        })
        .catch((error) => {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
          isSubmitting.value = false;
          resetModal();
        });
    };

    const download = async (id, name) => {
      try {
        const res = await repoHasilTryout.download(id)
        await downloadFile(res.data, name)
      } catch(error) {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
        };
    }

    const downloadReport = async (id, name) => {
      try {
        const res = await repoHasilTryout.downloadReport(id)
        await downloadFile(res.data, name)
      } catch(error) {
          if (error.response) {
            showToast(
              "Error",
              error.response.data.message,
              "danger",
              "AlertTriangleIcon"
            );
          } else if (error.request) {
            showToast(
              "Error",
              "Tidak bisa request data ke server",
              "danger",
              "AlertTriangleIcon"
            );
          } else {
            showToast("Error", error.message, "danger", "AlertTriangleIcon");
          }
        };
    }

    const handleChange = (value) => {
      filterValue.value = {...value}
    };

    const onSearch = (e) => {
      search.value = e.currentTarget.value;
    };

    const onChangeJenis = (value) => {
      form.value.jenis_id = value.id
    }

    return {
      isError,
      isLoading,
      isSubmitting,
      kategoryTryout,
      tableColumns,
      form,
      listPaketKategori,
      listKategoriTryout,
      listJenisTryout,
      listPaketTryout,
      currentPaketIndex,
      listJenis,
      targetedId,
      filterList,
      initialValue,
      filterValue,
      search,
      from,
      to,
      total,
      pageTotal,
      // methods
      setRowIndex,
      fetchData,
      resetModal,
      submitPaketTryout,
      updatekategoryTryout,
      deletePaketTryout,
      tambahPaketMapel,
      setSelectedPaketMapel,
      removePaketMapel,
      handleOk,
      show, modalLihat, showLihat,
      download,
      fetchJenis,
      setTargetedId,
      fetchDetail,
      showModal,
      handleChange,
      onSearch,
      onChangeJenis,
      downloadReport
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
